/* Import main Inspinia style */
@import 'assets/styles/style';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #e2e2e2;
}

.top-toolbar .p-toolbar {
    padding: 0px !important;
    color: #000;
    height: 75px;
    background-color: #fff;
    border-radius: 0;
    border-bottom: 2px solid #078342;
}

.main-body {
    height: calc(100vh - 150px + 1.5rem);
    background-color: #e2e2e2;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-body::-webkit-scrollbar {
    width: 8px;
}
.main-body::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
}

footer {
    height: 28px;
}

.user-info img {
    border-radius: 50% !important;
}

/* Customizações */

.p-menubar {
    padding-top: 0;
    border: 0px;
    background-color: transparent;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    border-radius: 0px !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #fff;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link-active .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link-active .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link-active .p-submenu-icon {
    color: #078342;
    font-weight: 700;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover {
    background-color: #e2e2e2;
    color: #078342;
    border-radius: 0px;
}

.p-submenu-list {
    border-radius: 5px;
}

.p-menubar .p-submenu-list {
    width: 15em;
}

// .p-menuitem-link-active .p-menuitem {
//   color: #078342;
//   border-radius: 0px;
// }

.p-menuitem-link-active {
    background-color: #e2e2e2;
    color: #078342;
    border-radius: 0;
}

app-navigation {
    z-index: 1000;
}

app-navigation .navbar-default {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #438811;
}

.navbar .dropdown-menu {
    margin-top: -10px;
    right: auto;
    left: auto;
}

.modal-content .ibox {
    margin-bottom: 0 !important;
}

.minimalize-styl-2 {
    float: none;
}

.profile-element {
    padding: 10px;
    top: 5px;
}

.nav-header {
    padding: 0;
}

.footer {
    font-size: 12px;
}

.pointer {
    cursor: pointer;
}
.table-w-1 {
    width: 4rem;
}
.table-w-2 {
    width: 8rem;
}
.table-w-3 {
    width: 12rem;
}
.table-w-4 {
    width: 12rem;
}
.table-w-5 {
    width: 16rem;
}
.table-w-6 {
    width: 20rem;
}
.table-w-7 {
    width: 24rem;
}
.table-w-8 {
    width: 28rem;
}
.table-w-9 {
    width: 32rem;
}

// CUSTOMIZAÇÕES DO TEMPLATE

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.2em;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    color: #495057;
    background: #fff;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 0.2rem 0.2rem;
}

.p-button-success {
    font-size: 12px;
    height: 28px;
    padding: 8px 25px;
    text-transform: uppercase;
    border-radius: 6px;
    background-color: #438811;
    min-inline-size: min-content;
}

.p-button-secondary {
    font-size: 12px;
    height: 28px;
    padding: 8px 25px;
    text-transform: uppercase;
    border-radius: 6px;
    background-color: #495057;
    min-inline-size: min-content;
}

.p-button-text {
    font-size: 12px;
    height: 28px;
    padding: 8px 25px;
    text-transform: uppercase;
    color: #438811;
    min-inline-size: min-content;
    border-color: #438811;
}

.p-button-danger {
    font-size: 12px;
    height: 28px;
    padding: 8px 25px;
    text-transform: uppercase;
    color: #d32f2f;
    min-inline-size: min-content;
    border-radius: 6px;
    border-color: #aa2424;
}

.icon-disabled {
    color: #999999 !important;
}

.p-picklist-list-wrapper ul {
    max-height: 400px;
    overflow-y: auto;
}
.p-picklist-list-wrapper ul::-webkit-scrollbar {
    width: 8px;
}
.p-picklist-list-wrapper ul::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 20px;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: #e2edda;
    color: #333333;
}

.title-radio {
    position: absolute;
    margin-top: -55px;
    font-size: 12px;
}

.contagem-carac {
    width: 100%;
    text-align: right;
    position: absolute;
    margin-left: -221px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background: #ffffff padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background: #ffffff padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #438811;
    border-color: #438811;
    color: #fff;
}

.required-float-label {
    margin-top: -0.9rem !important;
}

.msg-field-required {
    color: #f44336;
}

// Icones SVG
.icon-edit-custom {
    background: url('/assets/icons/md-create.svg');
    width: 18px;
    height: 18px;
}
.icon-edit-custom-disabled {
    background: url('/assets/icons/md-create-disabled.svg');
    width: 18px;
    height: 18px;
}
.icon-edit-custom-white {
    background: url('/assets/icons/md-create-white.svg');
    width: 18px;
    height: 18px;
}
.icon-ativo-custom {
    background: url('/assets/icons/md-checkmark-circle.svg');
    width: 18px;
    height: 18px;
}
.icon-ativo-custom-disabled {
    background: url('/assets/icons/md-checkmark-circle-disabled.svg');
    width: 18px;
    height: 18px;
}
.icon-inativo-custom {
    background: url('/assets/icons/md-close-circle.svg');
    width: 18px;
    height: 18px;
}

.icon-add-custom-disabled {
    background: url('/assets/icons/bookmark-plus-disabled.svg');
    width: 18px;
    height: 18px;
}
.icon-add-custom {
    background: url('/assets/icons/bookmark-plus.svg');
    width: 18px;
    height: 18px;
}

.icon-success-custom {
    background: url('/assets/icons/md-success-circle.svg');
    width: 60px;
    height: 60px;
}

.icon-error-custom {
    background: url('/assets/icons/md-error-circle.svg');
    width: 60px;
    height: 60px;
}

.icon-warn-custom {
    background: url('/assets/icons/md-warn-circle.png');
    width: 60px;
    height: 60px;
}

.minWidthOverride {
    top: -202px !important;
}

.dialog-form__spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}